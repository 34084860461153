import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { getFooterData } from "../../lib/footer";
import { getMenuByLocation, MenuData, getAllMenus } from "../../lib/menus";
const SocialMedia = dynamic(() => import("../Common/SocialMedia"));
function Footer() {
  const [footerData, setFooterData] = useState<any>(null);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const data = await getFooterData();
        setFooterData(data);
      } catch (error) {
        console.error("Error: front footer components fetching", error);
      }
    };

    fetchFooterData();
  }, []);

  // Get Main Menu Data Start

  const [mainMenu, setMainMenu] = useState<MenuData | null>(null);
  const [serviceMenu, setServiceMenu] = useState<MenuData | null>(null);

  const MenuName = "MENU_1";
  const ServiceMenu = "MENU_4";

  useEffect(() => {
    const fetchAllMenus = async () => {
      try {
        const allMenus = await getAllMenus();
        setMainMenu(getMenuByLocation(allMenus, MenuName));
        setServiceMenu(getMenuByLocation(allMenus, ServiceMenu));
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };
    fetchAllMenus();
  }, [MenuName, ServiceMenu]);
  // Get Main Menu Data end


  return (
    <footer className="footer lightgray">
      <div className="container">
        <div className="row">
          <div className="col-lg-4" data-aos="fade" data-aos-delay="100">
            <Link href="/" className="footer_logo">
              {footerData?.footer?.footer1?.footerLogo && (
                <Image
                  src={footerData?.footer?.footer1?.footerLogo?.sourceUrl}
                  alt={footerData?.footer?.footer1?.footerLogo?.altText}
                  width={200}
                  height={67}
                  loading="lazy"
                />
              )}
            </Link>
            <p>{footerData?.footer?.footer1?.footerDescription}</p>
            <div className="details">
              <div className="address">
                <i className="fa fa-map-marker"></i>
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: footerData?.footer?.footer1?.address || "",
                  }}
                ></div>
              </div>
              <ul>
                <li>
                  <Link
                    href={`tel:${
                      footerData?.footer?.footer1?.phoneNumber || "#1"
                    }`}
                  >
                    <i className="fa fa-phone"></i>{" "}
                    {footerData?.footer?.footer1?.phoneNumberText}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-lg-5 quick_links"
            data-aos="fade"
            data-aos-delay="200"
          >
            <h3>Company</h3>
            {/* <ul className="count2">
                        <li><Link href="/">Home</Link></li>
                        <li><Link href="/about">About</Link></li>
                        <li><Link href="/services">What We Do</Link></li>
                        <li><Link href="/case-studies">Case Studies</Link></li>
                        <li><Link href="#1">Hire a Developer</Link></li>
                        <li><Link href="/contact-us">Contact Us</Link></li>
                    </ul> */}
            {mainMenu && (
              <ul className="count2">
                {mainMenu.menuItems.nodes.map((menuItem) => (
                  <li key={menuItem?.id}>
                    <Link href={menuItem?.uri}> {menuItem?.label} </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div
            className="col-lg-3 quick_links"
            data-aos="fade"
            data-aos-delay="300"
          >
            <h3>Services</h3>
            {serviceMenu && (
              <ul>
                {serviceMenu.menuItems.nodes.map((menuItem) => (
                  <li key={menuItem?.id}>
                    <Link href={menuItem?.uri}> {menuItem?.label} </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="copyrights">
        <div className="container">
          <div
            className="fc_content"
            dangerouslySetInnerHTML={{
              __html: footerData?.footer?.copyright?.copyrightContent || "",
            }}
          ></div>
          <div className="social_media sm gray">
            <SocialMedia />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
