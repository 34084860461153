import { fetchAPI } from "./api";
export async function getFooterData() {
  const query = `
    query getFooterData {
        themeOptionsFooter {
          footer {
            cta {
              title
              description
              ctaButton {
                title
                url
              }
            }
            footer1 {
              footerLogo {
                altText
                sourceUrl
              }
              footerDescription
              address
              phoneNumberText
              phoneNumber
            }
            copyright {
              copyrightContent
            }
          }
        }
    }
    `;

  try {
    const data = await fetchAPI(query);

    const footerData = data?.themeOptionsFooter ?? null;
    return footerData;
  } catch (error) {
    console.error("Error fetching theme footer data:", error);
    return null;
  }
}
